import { useEffect } from "react";

const useFavicon = (faviconUrl: string) => {
  
  useEffect(() => {
    const favicon = document.querySelector(
      "link[rel*='icon']"
    ) as HTMLLinkElement;

    if (favicon) {
      favicon.href = faviconUrl;
    } else {
      const newFavicon = document.createElement("link");
      newFavicon.rel = "icon";
      newFavicon.href = faviconUrl;
      document.head.appendChild(newFavicon);
    }
  }, [faviconUrl]);
};

export default useFavicon;
