import { useLanguage } from "../../context/LanguageContext";
import { getSubDomain, isAskSamHost, navigateToURL } from "../../lib/utils";
import { User } from "lucide-react";

const NotLoggedInItem = () => {
  const { translate } = useLanguage();

  const handleLogin = () => {
    const subDomain = getSubDomain();

    if (isAskSamHost) {
      navigateToURL(
        `https://account.${subDomain}/?redirect=${window.location.href}`
      );
    } else {
      navigateToURL(
        `https://accounts.unitedwecare.com/?redirect=${window.location.href}`
      );
    }
  };
  return (
    <div className="divide-y sm:border border-dashed border-slate-400 dark:border-slate-700 py-5 rounded-2xl max-w-xl mx-auto absolute bottom-5 w-[95%] h-[calc(100vh-200px)] sm:h-max left-[2.5%] sm:bottom-1/3 sm:left-1/2 sm:-translate-x-1/2 sm:-translate-y-1/2">
      <div className="flex flex-col justify-center items-center gap-5 px-4 py-2 text-center text-gray-700 h-full -mt-24 sm:m-0">
        <div className="bg-muted dark:bg-background/80 p-3 rounded-full mt-auto">
          <User className="h-8 w-8 text-slate-600 dark:text-slate-300" />
        </div>
        <div className="space-y-2 mb-auto">
          <h5 className="mb-1 font-medium tracking-tight text-center text-xl">
            Please login to continue
          </h5>
          <p className="text-sm [&_p]:leading-relaxed text-center">
            You need to be logged in to view your documents
          </p>
        </div>

        <div className=" mt-auto w-full flex items-center border-t sm:border-0 p-3 sm:p-0 fixed md:relative bottom-0 left-0 z-10 bg-background/80 backdrop-blur-md">
          <button
            className="bg-primary-500 dark:bg-primary/80 px-5 py-2 rounded-full sm:rounded-lg text-sm text-white sm:m-auto w-full sm:w-max"
            onClick={handleLogin}
          >
            Login or Register
          </button>
        </div>
      </div>
    </div>
  );
};

export default NotLoggedInItem;
