import React, { useEffect, useState } from "react";
import Drawer from "../drawer/Drawer";

import { useAppState } from "../../context/AppStateContext";
import { ActionTypes } from "../../reducer/actionTypes";

import { useNavigate, useLocation } from "react-router-dom";

//Icons
import { Plus } from "lucide-react";

import { GetLocationDetails } from "../../api/_request";
import { useAuth } from "../../auth";

import SettingsOptions from "./SettingsOptions";
import { useLanguage } from "../../context/LanguageContext";
import {
  getSubDomain,
  hexToHSL,
  isAskSamHost,
  samFacesStaticList,
  setHostPrimaryColor,
  stellaFacesStaticList,
} from "../../lib/utils";

import { Helmet } from "react-helmet";
import { Skeleton } from "../skeleton/skeleton";
import useFavicon from "../../hooks/useFavicon";

const Header: React.FC = () => {
  const { state, dispatch } = useAppState();
  const { currentUser, setCurrentUser } = useAuth();
  const navigate = useNavigate();
  const { translate, setLanguage } = useLanguage();
  const location = useLocation();
  const search = location.search;

  const searchBarParams = new URLSearchParams(search);
  const faceID = searchBarParams.get("face-id");

  const [openFacesDrawer, setOpenFacesDrawer] = useState<boolean>(false);

  const [facesList, setFacesList] = useState<null | any[]>(null);

  //UseEffect for stuff that needs to run only once
  useEffect(() => {
    setFacesListCountryBased();
    setStellaFace();

    document.title = isAskSamHost ? "Sam" : "Stella";
  }, []);

  //console.log("state", state);

  useEffect(() => {
    ///Experimental
    redirectionViaCountry();
  }, [state?.userLocation, currentUser]);

  /// TODO: Add a Modal to ask the user before redirecting
  const redirectionViaCountry = () => {
    const hostMatch =
      window.location.host.includes(".ask-sam.ai") ||
      window.location.host.includes(".asksam.com.au");

    const subDomain = getSubDomain();

    if (
      state?.userLocation?.toLocaleLowerCase() === "australia" ||
      state?.userLocation?.toLocaleLowerCase() === "au"
    ) {
      if (!window.location.host.includes("localhost")) {
        if (!hostMatch) {
          const urlParams = window.location.href.split("?")[1];
          if (urlParams) {
            window.location.href = `https://chat.asksam.com.au/?${urlParams}`;
          } else {
            window.location.href = `https://chat.asksam.com.au/`;
          }
        }
      }

      const { hue, saturation, lightness } = hexToHSL("#1b409b");
    } else {
      const { hue, saturation, lightness } = hexToHSL("#73589B");
    }
  };

  const getUserLocationViaIP = async () => {
    try {
      const locationData = await GetLocationDetails();
      if (+locationData?.data?.code === 200) {
        const countryCode = locationData?.data?.data?.country;

        // console.log("countryCode", countryCode)
        dispatch({
          type: ActionTypes.SET_USER_LOCATION,
          payload: countryCode,
        });
      }
    } catch (error) {
      dispatch({
        type: ActionTypes.SET_USER_LOCATION,
        payload: "IN",
      });
    }
  };

  const setFacesListCountryBased = () => {
    const hostMatch =
      window.location.host.includes(".ask-sam.ai") ||
      window.location.host.includes(".asksam.com.au");

    if (hostMatch) {
      setFacesList(samFacesStaticList);
    } else {
      setFacesList(stellaFacesStaticList);
    }
  };

  const handleUpdateFaceInContext = (selectedFace: string) => {
    //Save in session storage
    sessionStorage.setItem("stellaAvatar", selectedFace);

    //Save in central state
    dispatch({
      type: ActionTypes.SET_STELLA_AVATAR,
      payload: selectedFace,
    });
  };

  const handleSelectStellaFace = (
    selectedFace: string,
    initialLoad: boolean
  ) => {
    if (selectedFace !== state.selectedFace) {
      handleUpdateFaceInContext(selectedFace);

      /*INTERNATIONALIZATION*/
      const newMessage = {
        response: "update",
        type: "notification",
        message: translate("AvatarChangeMessage"),
        update: "Avatar",
      };

      /*const newMessage = {
        response: "update",
        type: "notification",
        message: 'Avatar has been changed. Hope you like it!',
        update: "Avatar",
      };*/

      dispatch({
        type: ActionTypes.UPDATE_CHAT_HISTORY,
        payload: [...state.chatHistory, newMessage],
      });

      //Close the face selection Drawer
      handleCloseFacesDrawer();
    }
  };

  const setStellaFace = () => {
    const hostMatch =
      window.location.host.includes(".ask-sam.ai") ||
      window.location.host.includes(".asksam.com.au");

    if (faceID !== null && faceID !== undefined) {
      let filteredFace: StellaFaceItem;

      if (hostMatch) {
        filteredFace = samFacesStaticList.filter(
          (item) => item.id === +faceID
        )[0];
      } else {
        filteredFace = stellaFacesStaticList.filter(
          (item) => item.id === +faceID
        )[0];
      }

      if (filteredFace !== undefined) {
        handleUpdateFaceInContext(filteredFace.face);
      }
    } else {
      let selectedFace;
      if (hostMatch) {
        selectedFace = samFacesStaticList[0].face;
      } else {
        selectedFace = stellaFacesStaticList[0].face;
      }

      handleUpdateFaceInContext(selectedFace);
    }
  };

  const handleOpenFacesDrawer = () => {
    if (state?.isUserLoggedIn) setOpenFacesDrawer(true);
  };
  const handleCloseFacesDrawer = () => setOpenFacesDrawer(false);

  const toggleShowInputField = () => {
    dispatch({
      type: ActionTypes.SET_SHOW_INPUT,
      payload: !state.showInput,
    });
  };

  const toggleAudioMode = () => {
    dispatch({
      type: ActionTypes.TOGGLE_AUDIO_MODE,
    });
  };

  return (
    <div className="grid z-30 bg-white dark:bg-[#020611] fixed w-full top-0 left-0 md:rounded-t-2xl">
      <div className="bg-white/60 dark:bg-[#020611]  border-b border-solid border-slate-300/70 dark:border-slate-500 px-3 py-4 md:py-2 md:px-4 flex items-center justify-between gap-5 md:rounded-t-2xl">
        <div className="relative" onClick={handleOpenFacesDrawer}>
          <img
            src={state?.selectedFace}
            alt=""
            className="w-[55px] h-[55px] rounded-full object-cover border-2 border-solid border-white dark:border-slate-600 cursor-pointer"
          />
          {state?.isUserLoggedIn ? (
            <Plus
              className="bg-primary dark:bg-primary-dark text-white p-1 w-5 h-5 rounded-full absolute bottom-0 -right-1 border border-solid border-white dark:border-slate-700"
              style={{ backgroundColor: "var(--primary)" }}
            />
          ) : null}
        </div>

        {/* <span className="absolute top-4 left-0  flex h-3 w-3 my-auto ml-3">
          <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-400 opacity-75"></span>
          <span className="relative inline-flex rounded-full h-3 w-3 bg-green-500"></span>
        </span> */}

        <h3 className="text-md text-lg text-slate-800 dark:text-white flex-1 font-semibold">
          {/* INTERNATIONALIZATION */}
          {window.location.host.includes(".ask-sam.ai") ||
          window.location.host.includes(".asksam.com.au")
            ? translate("AskSam")
            : translate("Stella")}
        </h3>

        <div className="flex items-center gap-2">
          {/* Audio States */}
          {/* {state?.isAudioMode ? (
            <button
              className="aspect-square w-11 flex bg-primary dark:bg-primary-dark rounded-xl text-white"
              onClick={toggleAudioMode}
            >
              <Volume2 className="m-auto" />
            </button>
          ) : (
            <button
              className="aspect-square w-11 flex bg-primary/15 dark:bg-[#6d28d932] rounded-xl text-primary dark:text-white"
              onClick={toggleAudioMode}
            >
              <VolumeX className="m-auto" />
            </button>
          )} */}

          {/* <LanguageSelector /> */}
          {state?.isUserLoggedIn ? <SettingsOptions /> : null}
        </div>

        <Drawer isOpen={openFacesDrawer} onClose={handleCloseFacesDrawer}>
          <div>
            <h3 className="text-xl font-semibold leading-6 text-gray-900 dark:text-white mb-10">
              Choose Avatar
            </h3>
            <div className="grid grid-cols-4 md:grid-cols-5 gap-3 pb-4">
              {facesList?.map((item, id) => {
                return (
                  <button
                    key={id}
                    className={`rounded-full ${
                      state.selectedFace === item.face
                        ? "ring-offset-2 dark:ring-offset-0 ring-2 ring-primary dark:ring-primary-dark"
                        : ""
                    }`}
                    onClick={() => handleSelectStellaFace(item.face, false)}
                  >
                    <img
                      className="w-full h-full aspect-square rounded-full object-cover bg-slate-200"
                      src={item.face}
                      alt=""
                      loading="lazy"
                    />
                  </button>
                );
              })}
            </div>
          </div>
          <div className="mt-3"></div>
        </Drawer>
      </div>

      {/* <div className="px-3 py-3 bg-white border-b border-solid border-slate-300 flex items-center justify-between">
        <div className="flex items-center gap-2">
          <span className="relative flex h-3 w-3">
            <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-400 opacity-75"></span>
            <span className="relative inline-flex rounded-full h-3 w-3 bg-green-600"></span>
          </span>
          <p className="text-base font-semibold text-primary">LIVE Chat</p>
        </div>

        <div className="flex items-center gap-2">
          {state?.isAudioMode ? (
            <button
              className="aspect-square w-12 flex bg-primary  rounded-xl text-white"
              onClick={toggleAudioMode}
            >
              <Volume2 className="m-auto" />
            </button>
          ) : (
            <button
              className="aspect-square w-12 flex bg-primary/15 rounded-xl text-primary"
              onClick={toggleAudioMode}
            >
              <VolumeX className="m-auto" />
            </button>
          )}

          <button
            className={`aspect-square w-12 flex ${
              state.showInput
                ? "bg-primary text-white"
                : "bg-primary/15 text-primary"
            }  rounded-xl `}
            onClick={toggleShowInputField}
          >
            <TextCursorInput className="m-auto" />
          </button>
        </div>
      </div> */}
    </div>
  );
};

export default Header;
