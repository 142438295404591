import { FC, Fragment, useState, useEffect } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { SunMoon, ChevronsUpDown, Check } from "lucide-react";
import { useLanguage } from "../../context/LanguageContext";
import { TranslationKeys } from "../../context/LanguageContext";
import { useAppState } from "../../context/AppStateContext";
import { ActionTypes } from "../../reducer/actionTypes";
import { primaryColorValue } from "../../lib/utils";

// Predefined theme options.
const themeOptions: ThemeOption[] = [
  // { name: "System" },
  { name: "Light" },
  { name: "Dark" },
];

const ColorSchemeSelector: FC = () => {
  const { state, dispatch } = useAppState();
  const { translate, setLanguage } = useLanguage();

  // Use correct initial types for state.
  const [theme, setTheme] = useState<string>(
    localStorage.getItem("stellaTheme") || "Light"
  );
  const [selected, setSelected] = useState<string>(
    localStorage.getItem("stellaTheme") || "Light"
  );

  const [isRunningInIframe, setIsRunningInIframe] = useState<boolean>(false);

  // Effect to detect system color scheme preference.
  useEffect(() => {
    const matchMedia = window.matchMedia("(prefers-color-scheme: dark)");

    const handleChange = () => {
      setTheme(matchMedia.matches ? "Dark" : "Light");
    };

    // Set the initial theme based on the system preference.
    handleChange();

    // Listen for changes in the system theme preference.
    matchMedia.addEventListener("change", handleChange);

    // Cleanup listener on component unmount.
    return () => matchMedia.removeEventListener("change", handleChange);
  }, []);

  // Effect to apply the selected theme.
  useEffect(() => {
    const htmlEl = document.getElementsByTagName("html")[0];
    localStorage.setItem("stellaTheme", selected);
    if (
      theme === "Light" ||
      (theme === "System" &&
        !window.matchMedia("(prefers-color-scheme: dark)").matches)
    ) {
      htmlEl.classList.remove("dark");
      htmlEl.classList.add("light");
    } else {
      htmlEl.classList.remove("light");
      htmlEl.classList.add("dark");
    }

    dispatch({
      type: ActionTypes.SET_APP_THEME,
      payload: theme,
    });
  }, [theme]);

  // Adjusted to handle 'System' as a valid selection and properly manage class names.
  useEffect(() => {
    if (selected === "System") {
      setTheme(
        window.matchMedia("(prefers-color-scheme: dark)").matches
          ? "Dark"
          : "Light"
      );
    } else {
      setTheme(selected);
    }

    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({ action: "THEME_SELECT", value: theme })
      );
    }
  }, [selected]);

  useEffect(() => {
    if (isInIframe()) {
      console.log("React app is running inside an iframe");
      setIsRunningInIframe(true);
    } else {
      console.log("React app is not running inside an iframe");
      setIsRunningInIframe(false);
    }
  }, []);
  function isInIframe() {
    return window.frameElement !== null;
  }

  const rgbaColor = primaryColorValue
    ? `rgba(${parseInt(primaryColorValue.slice(1, 3), 16)}, ${parseInt(
        primaryColorValue.slice(3, 5),
        16
      )}, ${parseInt(primaryColorValue.slice(5, 7), 16)}, 0.10)` // Adjust the alpha value as needed
    : "";

  return (
    <>
      {!isRunningInIframe && (
        <div className="flex items-center gap-3 text-slate-600 dark:text-white h-[42px]">
          <SunMoon />
          {/* INTERNATIONALIZATION */}
          <p>{translate("ThemeLabel")}</p>
          {/* <p>Theme</p> */}
          <span className="ml-auto text-gray-400 lowercase">
            <Listbox value={selected} onChange={setSelected}>
              <div className="relative mt-1 z-30">
                <Listbox.Button className="relative w-full  rounded-lg bg-[#f2f3f4] dark:bg-[#3a4358] py-2 pl-3 pr-10 text-left focus:outline-none sm:text-sm text-gray-500 dark:text-gray-400  cursor-pointer">
                  {/* INTERNATIONALIZATION */}
                  <span className="block truncate">
                    {translate(selected as keyof TranslationKeys)}
                  </span>
                  {/* <span className="block truncate">{selected}</span> */}
                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                    <ChevronsUpDown className="h-5 w-5" aria-hidden="true" />
                  </span>
                </Listbox.Button>
                <Transition
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Listbox.Options className="absolute right-0 mt-1 max-h-60 w-[300px] overflow-auto rounded-2xl bg-white dark:bg-[#2f374a] py-2.5 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm z-50">
                    {themeOptions.map((option, index) => (
                      <Listbox.Option
                        key={index}
                        style={{
                          backgroundColor:
                            selected === option.name ? "var(--primary)" : "",
                        }}
                        className={`relative cursor-pointer rounded-lg select-none py-2 pl-10 pr-4 mx-2 text-gray-500 dark:text-gray-400 hover:bg-slate-200 dark:hover:bg-slate-800 ${selected === option.name ? "text-white dark:text-white": ""}`}
                        value={option.name}
                      >
                        {({ selected }) => (
                          <>
                            <span
                              className={`block truncate capitalize ${
                                selected ? "font-medium" : "font-normal"
                              }`}
                            >
                              {/* INTERNATIONALIZATION */}
                              {translate(option.name as keyof TranslationKeys)}
                              {/* {option.name} */}
                            </span>
                            {selected ? (
                              <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-white">
                                <Check className="h-5 w-5" aria-hidden="true" />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Transition>
              </div>
            </Listbox>
          </span>
        </div>
      )}
    </>
  );
};

export default ColorSchemeSelector;
