import React, { useState, useEffect } from "react";
import DOMPurify from "dompurify";
import Drawer from "../../drawer/Drawer";
import { DialogTitle } from "@headlessui/react";
import IframeDrawer from "../../drawer/IframeDrawer";
import { toast } from "sonner";

const HtmlRenderCard = ({ htmlContent }: { htmlContent: string }) => {
  const sanitizedHtml = DOMPurify.sanitize(htmlContent);
  const [isDarkMode, setIsDarkMode] = useState<boolean>(false);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openIframeModal, setOpenIframeModal] = useState<boolean>(false);
  const [dialogUrl, setDialogUrl] = useState<string>("");

  useEffect(() => {
    const handleLinkClick = (event: MouseEvent) => {
      const target = event.target as HTMLAnchorElement;

      // Check if it's a link
      if (target.tagName === "A") {
        const href = target.getAttribute("href") || "";

        // Handle telephone links
        if (href.startsWith("tel:")) {
          event.preventDefault(); // Prevent default behavior
          window.location.href = href;
          //  window.open(href); // This will open the associated app
        }

        // Handle website links
        else if (href.startsWith("https")) {
          event.preventDefault(); // Prevent default behavior
          // Show confirmation dialog

          //If it can open in iframe open in IframeDrawer

          //If WebView
          if (window.ReactNativeWebView) {
            window.ReactNativeWebView?.postMessage(
              JSON.stringify({
                action: "REDIRECT_TO_WEBSITE",
                url: href,
              })
            );

            // setDialogUrl(href);
            // setOpenDialog(true);
          } else {
            setDialogUrl(href);
            setOpenDialog(true);
          }
        }
      }
    };

    // Add event listener for links
    const container = document.getElementById("html-container");
    container?.addEventListener("click", handleLinkClick);

    setIsDarkMode(!window.matchMedia("(prefers-color-scheme: light)").matches);

    // Cleanup event listener on component unmount
    return () => {
      container?.removeEventListener("click", handleLinkClick);
    };
  }, []);


  const setCloseDialog = () => {
    setOpenDialog(false);
  };
  const setCloseIframeDialog = () => {
    setOpenIframeModal(false);
  };

  const onClickCopy = async () => {
    try {
      await navigator.clipboard.writeText(window.location.href);
      toast.success("Link copied successfully!");
      setCloseDialog();
    } catch (err) {
      console.log(err);
      toast.error("Something went wrong! Try copying link again!");
    }
  };

  return (
    <>
      <div
        id="html-container"
        className="text-sm !font-medium underline-offset-4"
        dangerouslySetInnerHTML={{ __html: sanitizedHtml }}
      />

      {/* Open confirmation dialog */}
      <Drawer isOpen={openDialog} onClose={setCloseDialog}>
        {/* <DialogTitle>Edit profile</DialogTitle> */}

        <div className="w-full">
          <h2 className="text-xl md:text-lg font-medium leading-6 text-gray-900 dark:text-white mb-10">
            Are you sure you want to visit&nbsp;
            <span className=" tracking-wide font-light">"{dialogUrl}" </span>?
          </h2>

          <div className="py-3 md:pb-0 flex flex-col md:flex-row md:justify-end gap-2 max-w-sm w-full md:ml-auto">
            <button
              //style={{ color: "var(--primary)" }}
              className="py-2.5 px-3 md:px-5 rounded-full md:rounded-lg border border-slate-400 dark:border-slate-600 text-sm font-medium "
              onClick={setCloseDialog}
            >
              Close
            </button>

            <button
              onClick={() => window.open(dialogUrl, "_system")}
              style={{ backgroundColor: "var(--primary)" }}
              className="py-2.5 px-3 md:px-5 rounded-full md:rounded-lg text-white text-sm font-medium"
            >
              Open
            </button>
          </div>

          <div className="hidden grid-cols-2 gap-3 my-5 md:mb-0 max-w-sm md:max-w-[300px] md:mx-auto">
            <button
              style={{ backgroundColor: "var(--primary)" }}
              className="py-2.5 px-3 rounded-full md:rounded-lg text-white text-sm font-medium"
              onClick={() => window.open(dialogUrl, "_system")}
            >
              Open
            </button>

            <button
              style={{ color: "var(--primary)" }}
              className="py-2.5 px-3 rounded-full md:rounded-lg border border-slate-400  text-sm font-medium"
              onClick={setCloseDialog}
            >
              Close
            </button>
          </div>
        </div>
      </Drawer>

      <IframeDrawer isOpen={openIframeModal} onClose={setCloseIframeDialog}>
        <iframe
          src={dialogUrl}
          width={100}
          height={100}
          className="w-full h-full min-h-[600px]"
        />
      </IframeDrawer>
    </>
  );
};

export default HtmlRenderCard;
