import axios, { AxiosRequestConfig } from "axios";
import { jwtDecode } from "jwt-decode"; // Ensure jwt_decode is installed and imported if you're using it
//import { LanguageResponse, Profile, SessionIDResponse } from "../types";
import Cookies from "js-cookie";

const Config = (): AxiosRequestConfig => {
  const profileItem = localStorage.getItem("profile");
  const profile: Profile | null = profileItem ? JSON.parse(profileItem) : null;
  return {
    headers: {
      "Content-Type": "application/json",
      Authorization: Cookies.get("authDataJwt") || "",
      // authenticationToken: "",
      "X-Email-Id": profile ? profile.Emailid : undefined,
      "X-API-Key": process.env.REACT_APP_XAPIKEY,
      DeviceType: "web",
      DeviceId: "123",
      Lat: "1",
      Long: "1",
    },
    data: {},
  };
};

export const fetchLanguages = async () => {
  try {
    // Using axios to make the HTTP request
    const response = await axios.get(
      `${process.env.REACT_APP_OODO_BASE_URL}stella/language/list`,
      Config()
    );

    // Axios automatically parses the JSON response, so no need to call .json()
    const token = response?.data?.data;
    const languageResponse: LanguageResponse = jwtDecode(token);

    return languageResponse;
  } catch (error) {
    console.error("fetchLanguages API issue:", error);
  }
};

export const UserDetailsService = async () => {
  try {
    const resp = await axios.get(
      `${process.env.REACT_APP_BASE_URL}api/Auth/GetUserbyId`,
      Config()
    );
    const resp_1 = resp.data;
    if (resp_1.data) resp_1.data = jwtDecode(resp_1.data);
    return resp_1;
  } catch (error) {
    console.error("UserDetailsService API issue: ", error);
  }
};

export const validateToken = async (token: string) => {
  try {
    const resp = await axios.post(
      `https://integrations.uwc.world/api/sso/validate_token`,
      { token },
      Config()
    );
    const resp_1 = resp.data;
    if (resp_1.data) {
      resp_1.data = jwtDecode(resp_1.data);
      return resp_1;
    } else {
      return false;
    }
  } catch (error) {
    console.error("validateToken API issue: ", error);
  }
};

export const getSessionID = async (body: any) => {
  try {
    const resp = await axios.post(
      `${process.env.REACT_APP_OODO_BASE_URL}im_livechat/get_session`,
      body,
      Config()
    );

    if (resp?.data) {
      // console.log("resp?.data", jwtDecode(resp?.data));
      const decodedData: SessionIDResponse = jwtDecode(resp?.data?.data);

      //console.log("decodedData", decodedData);
      return decodedData;
    } else {
      return false;
    }
  } catch (error) {
    console.error("getSessionID API issue: ", error);
  }
};

export const fetchConversation = async (userID: string, body: any) => {
  try {
    const startTime = new Date().getTime();
    const response = await axios.post(
      `${process.env.REACT_APP_OODO_BASE_URL}stella/conversation/${userID}`,
      body,
      Config()
    );
    const endTime = new Date().getTime();
    const responseTime = endTime - startTime; // Calculate the response time in milliseconds

    //console.log(`Response time: ${responseTime} ms`);
    let decodedData: any = jwtDecode(response?.data?.data);
    const responseValue = {
      responseTime,
      responseData: decodedData.responses,
    };
    return responseValue;
  } catch (error) {
    console.error("fetchConversation API issue: ", error);
  }
};

export const postLanguage = async (id: string | number, userID: string) => {
  const payload = {
    jsonrpc: "2.0",
    params: {
      lang_id: id,
    },
  };

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_OODO_BASE_URL}stella/user/language/${userID}`,
      JSON.stringify(payload),
      Config()
    );

    let decodedData: any = jwtDecode(response?.data?.data);
    //console.log("postLanguage", decodedData);
  } catch (error) {
    console.error("postLanguage API issue: ", error);
  }
};

export const callAwaitStart = async (body: any, ID: string) => {
  try {
    const response = await axios.post(
      `https://awtkafka.uwc.world/kafka-message/${ID}`,
      body,
      Config()
    );

    let decodedData: any = null;
    if (response?.data?.data !== null) {
      jwtDecode(response?.data?.data);
    }

    //console.log("DATA: ", decodedData);
    return decodedData.responses;
  } catch (error) {
    console.log("CallAwaitStart API issue: ", error);
  }
};

export const getArticleDetails = async (body: any) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_OODO_BASE_URL}new-uwc/view/post`,
      body,
      Config()
    );

    //console.log("RES", response.data.ResposeCode)
    if (response.data.ResponseCode == 200) {
      return response.data?.data?.data;
    } else {
      return false;
    }
  } catch (error) {
    console.error("getArticleDetails API issue: ", error);
  }
};

export const fetchSTTWhisper = async (data: any) => {
  try {
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://s2t-v2.uwc.world/transcribe-v2",
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Basic YWRtaW46WDlyJDN2ITJ3UXorOHBG",
      },
      data,
    };

    const resp = await axios.request(config);

    return resp?.data?.transcription;
  } catch (error) {
    console.error("fetchSTTWhisper API issue: ", error);
  }
};

export const TranslateWhisperTranslations = async (data: any) => {
  try {
    const response = await axios.post(
      "https://newtts.uwc.world/translate-internal-v2",
      {
        target: data?.target,
        text: data?.text,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
        auth: {
          username: "uwcadmin",
          password: "d4b66a2b-5c8f-48fb-b12f-e7f2a9b3c94d",
        },
      }
    );

    return response;
  } catch (error) {}
};

export const GetLocationDetails = async () => {
  try {
    const resp = await axios.get(`https://geoip.unitedwecare.com`, {
      headers: {
        "x-api-key": "gvZ3L7G5ToQb5F4Dre",
      },
    });
    //console.log("resp", resp);
    return resp;
  } catch (error) {
    console.error("GetSpecializationTypes: ", error);
    throw new Error("API error: " + error);
  }
};

export const SendUploadedDoc = async (body: any) => {
  try {
    const response = await axios.post(
      "https://s2t-v2.uwc.world/process-medical-file",
      body,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Basic YWRtaW46WDlyJDN2ITJ3UXorOHBG",
        },
      }
    );

    return response;
  } catch (error) {
    throw new Error("SendUploadedDoc API error: " + error);
  }
};

export const AddDocument = async (body: any) => {
  try {
    const response = await axios.post(
      `https://session-note.uwc.world/clinicalnotes/add-prescription`,
      body,
      {
        headers: {
          token: localStorage.getItem("Authorization")?.split(" ")[1],
          authenticationToken: Cookies.get("authDataJwt"),
          "X-API-Key": process.env.REACT_APP_XAPIKEY,
          DeviceType: "web",
          DeviceId: "123",
          Lat: "1",
          Long: "1",
        },
      }
    );

    return response;
  } catch (error) {}
};

export const GetMyDocuments = async (data: any) => {

//  console.log("authDataJwt", Cookies.get("authDataJwt"))
  try {
    const headers = {
      Accept: "application/json",
      token: data?.token,
      authenticationToken: Cookies.get("authDataJwt"),
    };

    // Construct the base URL
    let url = `https://session-note.uwc.world/clinicalnotes/list-prescriptions?user_id=${data?.userID}&count=${data?.count}&page=${data?.page}`;

    // Append the category if it is not null
    if (data?.category) {
      url += `&category=${data.category}`;
    }

    //Append the search if it is not empty string
    if (data?.search) {
      url += `&search=${data.search}`;
    }

    const response = await axios.get(url, { headers });

    return response;
  } catch (error) {
    // Handle error here if needed
    console.error("Error fetching documents:", error);
    throw error;
  }
};

export const updateUserIntakeData = async (
  token: string,
  userId: string,
  signatureLink: string
) => {
  try {
    const response = await axios.post(
      "https://session-note.uwc.world/stella2/update-user-intake-data",
      {
        user_id: `${userId}`,
        signature_link: signatureLink,
      },
      {
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
          token,
          authenticationToken: Cookies.get("authDataJwt"),
        },
      }
    );
    return response.data; // handle success response
  } catch (error) {
    console.error("Error updating user intake data:", error);
    throw error; // propagate the error
  }
};
