import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Languages, ChevronDown } from "lucide-react";

import "./Drawer.css";

const Drawer = ({ isOpen, onClose, onData, children }: DrawerProps) => {
  const [open, setOpen] = useState(true);

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 dark:bg-black/10 backdrop-blur-md bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-3 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-3xl bg-white dark:bg-[#252B39] px-3 pt-3 sm:pt-0 sm:px-4 text-left shadow-xl  transition-all sm:my-8 sm:w-[600px] sm:p-6 max-h-screen  w-full CustomDrawer">
                <div className="">
                  <div className="mt-3 text-center sm:mt-5 flex flex-col">
                    <>{children}</>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default Drawer;
