import React, { useEffect, useState } from "react";
import {
  avatarImageClass,
  buttonItemClass,
  responseAreaClass,
  responseButtonsClass,
  serverResponseClass,
} from "../../helpers/helperClasses";
import { useAppState } from "../../context/AppStateContext";
import { useLanguage } from "../../context/LanguageContext";
import { getSubDomain, isAskSamHost, navigateToURL } from "../../lib/utils";

const NotLoggedInChatBody = () => {
  const { state, dispatch } = useAppState();
  const { translate } = useLanguage();

  const handleLogin = () => {
    const subDomain = getSubDomain();

    if (isAskSamHost) {
      navigateToURL(
        `https://account.${subDomain}/?redirect=${window.location.href}`
      );
    } else {
      navigateToURL(
        `https://accounts.unitedwecare.com/?redirect=${window.location.href}`
      );
    }
  };

  return (
    <div className={responseAreaClass}>
      <div className="avatar relative">
        <img
          src={state.selectedFace}
          className={avatarImageClass}
          alt="stella"
        />
      </div>

      <div className="space-y-3">
        <div
          className={`response ${serverResponseClass} ${state?.chatDirection}`}
        >
          {/* INTERNATIONALIZATION */}

          {/* Hi there! I'm Stella! */}
          {isAskSamHost
            ? translate("NotLoggedInMessage1AU")
            : translate("NotLoggedInMessage1")}
        </div>

        <div
          className={`response ${serverResponseClass} ${state?.chatDirection}`}
        >
          {translate("NotLoggedInMessage2")}
          {/* Logging in allows me to offer you personalized assistance and a
                tailored experience. */}
        </div>

        <div
          className={`response ${serverResponseClass} ${state?.chatDirection}`}
        >
          {/* INTERNATIONALIZATION */}
          {translate("NotLoggedInMessage3")}
          {/* Don't have an account? Create one to get started on your
                wellness journey. */}
        </div>

        <div className={responseButtonsClass}>
          <div className={buttonItemClass} onClick={handleLogin}>
            {/* INTERNATIONALIZATION */}
            {translate("LoginOrRegister")}
            {/* Login or Register */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotLoggedInChatBody;
