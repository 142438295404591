import { FC, Fragment, useState, useEffect } from "react";
import { Listbox, Dialog, Transition } from "@headlessui/react";
import {
  X,
  Mail,
  SunMoon,
  Globe,
  ChevronsUpDown,
  Check,
  LogOut,
  Search,
} from "lucide-react";

import { useAppState } from "../../context/AppStateContext";
import { ActionTypes } from "../../reducer/actionTypes";
import { fetchLanguages, postLanguage } from "../../api/_request";

import { TranslationKeys, useLanguage } from "../../context/LanguageContext";

import "./LanguageSelectorAlt.css";
import { Speech } from "lucide-react";
import { STTOptions } from "../../lib/utils";

const STTLanguageSelector = () => {
  //console.log("first", i18n)
  const { state, dispatch } = useAppState();
  const cachedLanguages = sessionStorage.getItem("languagesList");
  const prevSelectedLang = localStorage.getItem("stellaLanguage");
  const { translate, setLanguage } = useLanguage();

  const [languageList, setLanguageList] = useState<any[]>([]);
  const [searchLanguageInput, setSearchLanguageInput] = useState<string | null>(
    null
  );
  const [filteredLanguageList, setFilteredLanguageList] = useState<any>([]);
  const [hasRun, setHasRun] = useState<boolean>(false);

  useEffect(() => {
    if (searchLanguageInput !== null) {
      const filteredLanguages = languageList.filter((item) =>
        item.name.toLowerCase().includes(searchLanguageInput.toLowerCase())
      );

      if (searchLanguageInput !== null) {
        setFilteredLanguageList(filteredLanguages);
      }
    }
  }, [searchLanguageInput]);

  const handleLanguageChange = (ID: number, name: string, key: string) => {
    const filteredSTTOption = STTOptions?.filter(
      (item: any) => item?._id === ID
    );
    localStorage.setItem(
      "stellaSTTLanguage",
      JSON.stringify(filteredSTTOption[0])
    );

    //Send to React Native App
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView?.postMessage(
        JSON.stringify({
          action: "SET_STT_LANGUAGE",
          data: filteredSTTOption[0],
        })
      );
    }

    dispatch({
      type: ActionTypes.SET_STT_LANGUAGE,
      payload: name,
    });
  };

  const selectedLanguageItem = languageList?.filter(
    (language) => language.name === state?.sttLanguage
  );

  return (
    <>
      <div className="flex items-center pt-2 gap-3 text-slate-600 dark:text-white min-h-[42px]">
        <Speech />
        {/* INTERNATIONALIZATION */}

        <div className="">
          <span className="text-xs dark:text-green-100 bg-green-200 px-2 py-0.5 rounded-full font-light text-green-600 dark:bg-green-500/40 ring-1 ring-inset ring-green-300 dark:ring-green-600">
            {translate("Beta")}
          </span>
          <p>{translate("VoiceTranslation")}</p>
        </div>
        {/* <p>Language</p> */}

        <span className="ml-auto text-gray-400 lowercase">
          <Listbox value={state?.sttLanguage}>
            <div className="relative mt-1">
              <Listbox.Button className="relative w-full  cursor-pointer rounded-lg bg-[#f2f3f4] dark:bg-[#3a4358] py-2 pl-3 pr-10 text-left  focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                <span className="captalize block truncate text-gray-500 dark:text-gray-400">
                  {translate(state?.sttLanguage as keyof TranslationKeys)}
                </span>
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <ChevronsUpDown
                    className="h-5 w-5 text-gray-500 dark:text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              </Listbox.Button>
              <Transition
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="languagelist--container antialiased absolute right-0 mt-1 max-h-60 w-[300px] overflow-auto rounded-2xl bg-white dark:bg-[#2f374a] py-2 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
                  {STTOptions.map((language: any, languageIdx: number) => (
                    <Listbox.Option
                      key={languageIdx}
                      style={{
                        backgroundColor:
                          state?.sttLanguage === language.name
                            ? "var(--primary)"
                            : "",
                      }}
                      className={`relative select-none py-2 pl-10 pr-4 mx-2 cursor-pointer rounded-lg ${
                        state?.sttLanguage === language.name
                          ? "bg-primary text-white"
                          : "text-gray-500 dark:text-gray-400 hover:bg-slate-200 dark:hover:bg-slate-800"
                      }`}
                      value={language.name}
                      onClick={() =>
                        handleLanguageChange(
                          language._id,
                          language.name,
                          language.key
                        )
                      }
                    >
                      {({ selected }) => (
                        <div>
                          <span
                            className={`block truncate capitalize ${
                              selected ? "font-medium" : "font-normal"
                            }`}
                          >
                            {translate(language.name as keyof TranslationKeys)}
                          </span>
                          {selected ? (
                            <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-white">
                              <Check className="h-5 w-5" aria-hidden="true" />
                            </span>
                          ) : null}
                        </div>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </Listbox>
        </span>
      </div>
    </>
  );
};

export default STTLanguageSelector;
