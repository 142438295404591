import { FC, Fragment, useState } from "react";
import {
  Listbox,
  Dialog,
  Transition,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import {
  X,
  Mail,
  SunMoon,
  Globe,
  ChevronsUpDown,
  Check,
  LogOut,
  MonitorSmartphone,
} from "lucide-react";
import { useAuth } from "../../auth";
import ColorSchemeSelector from "../header/ColorSchemeSelector";
import LanguageSelectorAlt from "../header/LanguageSelectorAlt";
import { useLanguage } from "../../context/LanguageContext";
import { useAppState } from "../../context/AppStateContext";
import { Files } from "lucide-react";
import FullScreenBottomDrawer from "./FullScreenBottomDrawer";
import { useNavigate } from "react-router-dom";
import STTLanguageSelector from "../header/STTLanguageSelector";
import Cookies from "js-cookie";
import { getSubDomain } from "../../lib/utils";

const SettingsDrawer: FC<SettingsDrawerProps> = ({ isOpen, onClose }) => {
  const { currentUser, logout } = useAuth();
  const { state } = useAppState();
  const { translate, setLanguage } = useLanguage();
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState();

  const handleLogout = () => {
    const subdomain = getSubDomain();

   // console.log("subdomain", subdomain)

    Cookies.remove("authUserRole", {
      domain: `.${subdomain}`,
      path: "/",
      HttpsOnly: true,
    });
    Cookies.remove("authToken", {
      domain: `.${subdomain}`,
      path: "/",
      HttpsOnly: true,
    });
    Cookies.remove("authToken");
    logout();
  };
  //console.log("open", open);
  return (
    <Dialog as="div" className="relative z-50" open={isOpen} onClose={onClose}>
      <div className="fixed inset-0" />

      <div className="fixed inset-0 overflow-hidden bg-gray-500 dark:bg-black/10 backdrop-blur-md bg-opacity-75 transition-opacity">
        <div className="absolute inset-0 overflow-hidden">
          <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full">
            <DialogPanel
              transition
              className="pointer-events-auto w-screen max-w-md transform transition duration-500 ease-in-out data-[closed]:translate-x-full sm:duration-700 md:p-3"
            >
              <div className="flex h-full flex-col overflow-y-auto bg-white dark:bg-[#252B39]  py-6 shadow-xl md:rounded-2xl">
                <div className="px-4 sm:px-6">
                  <div className="flex items-start justify-between relative">
                    <DialogTitle className="text-lg font-semibold leading-6 text-black dark:text-white w-full text-center">
                      {translate("Settings")}
                    </DialogTitle>
                    <div className="ml-3 flex h-7 items-center">
                      <button
                        type="button"
                        className="absolute right-0 top-0 rounded-full w-8 h-8 bg-[#f2f3f4] dark:bg-[#3a4358]  text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                        onClick={onClose}
                      >
                        <span className="sr-only">Close panel</span>
                        <X className="h-6 w-6 m-auto" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                </div>
                <div className="relative mt-6 flex-1 px-4 sm:px-6">
                  <div className="h-full flex flex-col pt-5 gap-8">
                    {/* <h6 className="uppercase tracking-wide text-gray-600 text-sm">
                          Account
                        </h6> */}
                    <div className="px-5 py-3 rounded-2xl bg-[#f2f3f4] dark:bg-[#3a4358] divide-y divide-solid divide-slate-300 dark:divide-slate-500 grid space-y-2 text-sm shadow-sm">
                      {state?.userEmail !== null &&
                        state?.userEmail !== undefined &&
                        state?.userEmail !== "" && (
                          <div className="flex items-center gap-3 text-slate-600 dark:text-white h-[42px] ">
                            <Mail />
                            {/* INTERNATIONALIZATION */}
                            <p>{translate("EmailLabel")}</p>
                            {/* <p>Email</p> */}

                            <span className="ml-auto text-gray-500 dark:text-gray-400 lowercase ">
                              {state?.userEmail}
                            </span>
                          </div>
                        )}

                      <ColorSchemeSelector />

                      <LanguageSelectorAlt />
                      <STTLanguageSelector />
                    </div>

                    <div className="hidden px-5 py-3 rounded-2xl bg-[#f2f3f4] dark:bg-[#3a4358] divide-y divide-solid divide-slate-300 dark:divide-slate-500 space-y-2 text-sm shadow-sm">
                      <div className="flex items-center gap-3 text-slate-800 dark:text-white h-[42px] ">
                        <MonitorSmartphone />
                        <div>
                          <p>App Version</p>
                          <span className="text-xs text-gray-500 dark:text-gray-400">
                            1.7.00
                          </span>
                        </div>
                      </div>
                    </div>

                    <div
                      className="px-5 py-3 rounded-2xl bg-[#f2f3f4] hover:bg-slate-100 dark:bg-[#3a4358] divide-y divide-solid divide-slate-300 dark:divide-slate-500 space-y-2 text-sm shadow-sm cursor-pointer"
                      onClick={() => navigate("/documents")}
                    >
                      <div className="flex items-center gap-3 text-slate-600 dark:text-white h-[42px] ">
                        <Files />

                        <p>{translate("MyDocuments")}</p>

                        {state?.myDocumentsCount !== null && (
                          <span
                            //style={{ background: "var(--primary)" }}
                            className="ml-auto px-2.5 py-0.5 text-white rounded-xl text-xs bg-blue-600"
                          >
                            {state?.myDocumentsCount}
                          </span>
                        )}
                      </div>
                    </div>
                    <FullScreenBottomDrawer
                      isOpen={open}
                      onClose={() => setOpen(false)}
                    />

                    {/* Hidden for now */}
                    {/* Might not  be required */}
                    {!window.ReactNativeWebView && (
                      <div
                        className="items-center gap-3 bg-red-50 dark:bg-red-400/20 text-red-500 px-5 py-3 rounded-xl mt-auto cursor-pointer inline-flex"
                        onClick={handleLogout}
                      >
                        <LogOut />
                        <span className="text-sm">Sign Out</span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </DialogPanel>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default SettingsDrawer;
