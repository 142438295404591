import React, { useRef, useState } from "react";
import ButtonSuggestionItem from "../suggestions/ButtonSuggestionItem";
import SuggestionItem from "../suggestions/SuggestionItem";

const SmartSuggestionsArea = () => {
  const scrollRef = useRef<HTMLDivElement>(null);

  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [startX, setStartX] = useState<number>(0);
  const [scrollLeft, setScrollLeft] = useState<number>(0);

  /*----------- SCROLL SUGGESTIONS BLOCK ------------- */

  const onMouseDown = (e: any) => {
    if (scrollRef.current) {
      setIsDragging(true);
      setStartX(e.pageX - scrollRef.current.offsetLeft);
      setScrollLeft(scrollRef.current.scrollLeft);
    }
  };

  const onMouseUp = () => {
    setIsDragging(false);
  };

  const onMouseMove = (e: any) => {
    if (!isDragging || !scrollRef.current) return;
    e.preventDefault();
    const x = e.pageX - scrollRef.current.offsetLeft;
    const walk = (x - startX) * 2; // The number 2 here multiplies the scroll speed
    scrollRef.current.scrollLeft = scrollLeft - walk;
  };

  return (
    <>
      {/* SUGGESTIONS AREA HIDDEN FOR NOW*/}
      {/* Remove class hidden */}
      <div className=" w-screen max-w-[73rem] px-2 md:px-16 mx-auto bg-white -mb-0.5 z-1 border-t md:border-none hidden">
        <h6 className="dark:text-white font-medium p-2">
          What do you want to do?
        </h6>
        <div
          className="overflow-x-auto gap-3 flex w-full px-2 py-2 suggestionScroll"
          ref={scrollRef}
          onMouseDown={onMouseDown}
          onMouseUp={onMouseUp}
          onMouseLeave={onMouseUp}
          onMouseMove={onMouseMove}
          style={{ cursor: isDragging ? "grabbing" : "grab" }}
        >
          {/* Videos */}

          <SuggestionItem type={"Video"} />
          <SuggestionItem type={"Video"} />
          <SuggestionItem type={"Video"} />

          {/* Mood Area */}
          {/* <MoodSuggestionItem /> */}

          {/* Smart Buttons */}
          {/* <ButtonSuggestionItem text={"Add Name"} />
          <ButtonSuggestionItem text={"Add Age"} />
          <ButtonSuggestionItem text={"Add Address"} /> */}
        </div>
      </div>
    </>
  );
};

export default SmartSuggestionsArea;
