import React from "react";
import AudioResponse from "../../responses/AudioResponse";
import {
  myResponseClass,
  responseAreaClass,
} from "../../../helpers/helperClasses";

const AudioChatResponse = ({ chat }: any) => {
  return (
    <div key={chat._id}>
      {chat.type === "audio" ? (
        <div className="response-area">
          <div
            style={{ backgroundColor: "var(--primary)" }}
            className={myResponseClass}
          >
            <AudioResponse audioUrl={chat.url} responseType="my" />
          </div>
        </div>
      ) : (
        <div className={responseAreaClass}>
          <div
            style={{ backgroundColor: "var(--primary)" }}
            className={myResponseClass}
          >
            {chat?.uploadedItemURL ? (
              <img
                src={chat.uploadedItemImage}
                className="rounded-xl max-w-[280px] md:max-w-xs object-cover -m-3 border bg-white"
              />
            ) : null}

            {chat.message !== "" && chat.type !== "signature" && (
              <p className={`${chat?.uploadedItemURL && "mt-3"}`}>
                {chat.message}
              </p>
            )}
          </div>

          {/* <div className="avatar">
        <img
          src={currentUser?.ProfileImage}
          alt="My Avatar"
          className={`${avatarImageClass}`}
        />
        <span></span>
      </div> */}
        </div>
      )}
    </div>
  );
};

export default AudioChatResponse;
