import React, { FC, useState } from "react";
import { Play } from "lucide-react";

interface SuggestionItemProps {
  type: string;
}

const SuggestionItem: FC<SuggestionItemProps> = ({ type }) => {
  const handleVideoClick = () => {};
  return (
    <div className="border border-gray-400/60 p-2 rounded-2xl flex items-center gap-3 min-w-[350px] max-w-[350px] w-full">
      <div className="relative">
        <img
          src="https://images.pexels.com/photos/20411329/pexels-photo-20411329/free-photo-of-a-plate-of-strawberries-and-a-cup-of-coffee.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
          className="h-24 w-auto aspect-square object-cover rounded-xl"
        />
        <button
          className="h-8 w-8 bg-white backdrop-blur-md rounded-full flex items-center justify-center absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
          onClick={handleVideoClick}
        >
          <Play className="w-5 h-5 ml-1" style={{ color: "var(--primary)" }} />
        </button>
      </div>

      <div className="space-y-3">
        <h5 className="text-lg font-medium leading-6 dark:text-white">
          10 tips for dealing with accordingly
        </h5>
        <div className="flex items-center gap-3">
          <span className="text-sm text-gray-400 dark:text-green-600 font-medium tracking-widest">
            VIDEO
          </span>
          {/* <button
            className="py-1 px-3 rounded-full text-primary dark:text-indigo-500 border border-primary/40 dark:border-indigo-500/60"
            onClick={handleVideoClick}
          >
            Watch Now
          </button> */}
        </div>
      </div>
    </div>
  );
};

export default SuggestionItem;
